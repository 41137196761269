import React, { useState, useEffect } from 'react';
import { Checkbox } from '~/components/Form';
import Amount from '../Form/Amount/Amount';
import { ProductKeys, InvestmentTypeType, RecommendationType } from '~/hooks/useOnboarding';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { AmountsType, selectCurrentCompanyState, setOnboarding } from '~/store/application/applicationSlice';

import { RootState } from '~/store';
import { setConnectedProvider, updateSyncStatus } from '~/store/providers/providersSlice';
import { ProviderSyncStatus } from '~/store/providers/providersSlice';
import { LoaderButton } from '../LoaderButton/LoaderButton';
import useSessionStorage from '~/hooks/useSessionStorage';
import useTranslation from 'next-translate/useTranslation';
import { Radio } from '@monto/react-common-ui';
import { Entity } from '~/sdk/internal/v1/company/providers';
import useAppState from '~/hooks/useApp';

export interface OnboardingTestProps {
  recommendations: RecommendationType
}

// Define the component
const OnboardingTest = () => {

  const appState = useAppState();
  const providers = useSelector((state: RootState) => state.providers);
  const { t } = useTranslation();
  const currentCompanyState = appState.company;

  const onboardingAmount = useSessionStorage('onboarding-amount');

  const [desiredAmount, setDesiredAmount] = useState(0);
  const [localAmounts, setLocalAmounts] = useState(currentCompanyState?.onboarding.availableAmounts);
  const [localInvestmentType, setLocalInvestmentType] = useState(currentCompanyState?.onboarding.investmentType);

  // allow to test with dummy provider
  const dummyProvider: Entity = providers.connectedProvider || {
    id: 'dummy',
    displayName: '24Seven Office',
    isSyncing: false,
    syncedAt: '1980-09-03',
    createdAt: '1980-09-03',
    images: {
      logo: {
        large: '/images/Image_BL 1.png',
        thumb: '/images/Image_BL 1.png',
      }
    },
    settings: {
      automatedBookkeeping: false,
    },
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    setDesiredAmount(onboardingAmount.get());
    dispatch(setOnboarding({ desiredAmount: onboardingAmount.get() }));
  }, []);

  useEffect(() => {
    setLocalInvestmentType(currentCompanyState?.onboarding.investmentType)
  }, [currentCompanyState?.onboarding.investmentType])

  // Local state to handle the inputs
  useEffect(() => {
    setLocalAmounts(currentCompanyState?.onboarding.availableAmounts); // Update local state when the prop 'amounts' changes
  }, [currentCompanyState?.onboarding.availableAmounts]);

  // Handle input change
  const handleInputChange = (product: ProductKeys, value: number) => {
    const updatedAmounts = {
      ...localAmounts,
      [product]: Number(value),
    } as AmountsType;

    setLocalAmounts(updatedAmounts);
    dispatch(setOnboarding({ availableAmounts: updatedAmounts }));
  };

  useEffect(() => {
    setDesiredAmount(currentCompanyState?.onboarding.desiredAmount || 0)
  }, [currentCompanyState?.onboarding.desiredAmount]);


  // Sync changes
  const syncStart = () => {
    dispatch(updateSyncStatus(ProviderSyncStatus.Syncing))
  };

  const syncStop = () => {
    dispatch(updateSyncStatus(ProviderSyncStatus.Ready))
  };

  const updateAmountHandler = (amount: number) => {
    setDesiredAmount(amount);
    onboardingAmount.set(amount);
    dispatch(setOnboarding({ desiredAmount: amount }));
  };

  const handleLoanTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as InvestmentTypeType;

    setLocalInvestmentType(val)
    dispatch(setOnboarding({ investmentType: val }));
  };

  const handleProviderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setConnectedProvider(providers.connectedProvider === null ? dummyProvider : null))
  };

  const handleSi = () => {
    const current = currentCompanyState?.excludedProduct;
    appState.setExcludedProduct(current === 'si' ? undefined : 'si');
  };

  return (
    <div data-component="OnboardingTest" className="form">
      <h1>Desired: <strong>SEK {desiredAmount}</strong></h1>
      <div>
        <Amount
          value={desiredAmount !== 0 ? desiredAmount : ''}
          onChange={(amount) => updateAmountHandler(Number(amount))}
        />
      </div>
      <hr />
      <div className="investment-type">
        <label htmlFor="continuous">
          <Radio
            name="investType"
            checked={localInvestmentType === 'continuous'}
            id="continuous"
            value={'continuous'}
            onChange={(e) => handleLoanTypeChange(e)}
          />
          Continuous
        </label>
        <label htmlFor="one-time">
          <Radio
            name="investType"
            checked={localInvestmentType === 'one-time'}
            id="one-time"
            value="one-time"
            onChange={(e) => handleLoanTypeChange(e)}
          />
          One-time
        </label>
      </div>
      <hr />
      {localAmounts && Object.entries(localAmounts).map(([product, amount]) => (
        <div key={product}>
          <label>{t('onboarding:products.' + product)}
            {product === 'si' && <a className="button tertiary" onClick={handleSi}>({currentCompanyState?.excludedProduct === 'si' ? 'include' : 'exclude'})</a>}
          </label>
          <Amount
            value={amount !== 0 ? amount : 0}
            onChange={(amount) => handleInputChange(product as ProductKeys, Number(amount))}
            prepend={'SEK'}
            disabled={currentCompanyState?.excludedProduct == product}
            min="1000"
            max="1 000 000"
            placeholder={t('form:amount-placeholder')}
          />
        </div>
      ))}
      <hr />
      <h4>Emulate syncing</h4>
      <div className="investment-type">
        <label htmlFor="has-provider">
          <Checkbox
            name="has-provider"
            checked={providers.connectedProvider !== null}
            id="has-provider"
            onChange={handleProviderChange}
          />
          Has connected provider
        </label>
      </div>
      <div className="buttons">
        {providers.syncStatus === ProviderSyncStatus.Syncing && <LoaderButton loading={true}></LoaderButton>}
        {providers.syncStatus !== ProviderSyncStatus.Syncing && (
          <button className="button primary" onClick={syncStart}>Sync Start</button>
        )}
        <button className="button seondary" onClick={syncStop}>Sync Stop</button>
      </div>
      <hr />
    </div>
  );
};

export default OnboardingTest;
