import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedBaseResponse, EmptyBaseResponse } from '~/sdk/shared';
import baseQuery from './base-query';
import { Entity as ApplicationEntity, DeleteRequest, ListRequest } from '~/sdk/internal/v1/company/applications/';

export const applicationsApi = createApi({
  reducerPath: 'applicationsApi',
  tagTypes: ['Applications'],
  baseQuery,
  endpoints: (builder) => ({
    getApplications: builder.query<PaginatedBaseResponse<ApplicationEntity>, ListRequest | undefined>({
      query: (req) => {
        const queryString = `size=${req?.size || 25}&page=${req?.page || 1}`;
        return {
          url: `/internal/v1/company/applications?${queryString}`,
          method: 'GET'
        };
      },
      providesTags: (result) => result ? [{ type: 'Applications', id: 'LIST' }] : [],
    }),
    deleteApplication: builder.mutation<EmptyBaseResponse, DeleteRequest>({
      query: (req: DeleteRequest) => ({
        url: `/internal/v1/company/applications/${req.advancementId}`,
        method: 'DELETE',
        body: {
          data: {
            target: req.target
          }
        }
      }),
      invalidatesTags: [{ type: 'Applications', id: 'LIST' }],
    }),
  }),
});

export const {
  useGetApplicationsQuery,
  useDeleteApplicationMutation
} = applicationsApi;
