import API from '~/sdk/client'
import { BaseResponse } from '~/sdk/shared'

export interface GeneralEntity {
  creditScoreType: number
  minimumSellPrice: number | null
  feePerInvoice: number 
  annualReportThreshold: number
}

export async function get(): Promise<BaseResponse<GeneralEntity>> {
  return await API.get('internal/v1/settings/general')
}
