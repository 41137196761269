import { useSelector } from 'react-redux';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { ProductKeys } from './useOnboarding';


import {
  CompanyOnboardingType,
  selectCurrentCompanyState,
  setCurrentCompanyId as setAppCompanyId,
  setOnboarding as setOnboardingAction,
  setExcludedProduct as setExcludedProductAction,
  setLoadingState,
  resetAppState,
  CompanyState,
  updateCompany
} from '~/store/application/applicationSlice';
import { RootState } from '~/store';

const useAppState = () => {

  const currentCompanyState = useSelector(selectCurrentCompanyState);
  const app = useSelector((state: RootState) => state.application);

  const dispatch = useAppDispatch();

  function setOnboarding(data: Partial<CompanyOnboardingType>) {
    dispatch(setOnboardingAction(data));
  }

  function setExcludedProduct(product: ProductKeys | undefined) {
    dispatch(setExcludedProductAction(product));
  }

  function getRefContext() {
    return currentCompanyState?.refContext;
  }

  function getAppContext() {
    return currentCompanyState?.appContext;
  }

  function setStatus(state: 'init' | 'ready' | 'loading') {
    dispatch(setLoadingState(state));
  }

  function reset(companyId?: number) {
    dispatch(resetAppState(companyId));
  }

  function setCurrentCompanyId(id?: number) {
    dispatch(setAppCompanyId(id));
  }

  function updateCurrentCompany(data: Partial<CompanyState>) {
    dispatch(updateCompany(data));
  }

  return {
    data: app,
    status: app.status,
    company: currentCompanyState,
    onboarding: currentCompanyState?.onboarding,
    setOnboarding,
    getRefContext,
    getAppContext,
    setExcludedProduct,
    setCurrentCompanyId,
    updateCurrentCompany,
    setStatus,
    reset,
    dispatch
  };
};

export default useAppState;
