import { ButtonHTMLAttributes, ReactNode } from 'react';
import { Loader } from '../common/Loader/Loader';
import classNames from 'classnames'

interface LoaderButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading: boolean,
  children?: ReactNode,
  variant?: 'primary' | 'secondary' | 'tertiary' | 'cta' | 'negative',
  className?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

export const LoaderButton = (props: LoaderButtonProps) => {

  const {
    loading = false,
    variant = 'primary',
    children,
    className,
    onClick,
    ...rest
  } = props;

  const loaderColor = variant === 'primary' ? 'white' : 'primary';

  return (
    <button
      data-component="LoaderButton"
      className={classNames('button', variant, className)}
      data-loading={loading}
      onClick={onClick}
      {...rest}
    >
      {loading && <Loader color={loaderColor} />}

      {!loading && (
        <>
          {children}
        </>
      )}

    </button>
  )
};
