import { ChangeEvent, forwardRef, FunctionComponent, InputHTMLAttributes, KeyboardEvent, Ref, useRef, useState } from 'react'
import { HasClassName } from '@monto/react-common-ui'
import { Loader } from '@monto/react-common-ui'
import { CloudUploadIcon } from '@monto/react-common-ui'

export interface FileUploadButtonProps extends HasClassName, InputHTMLAttributes<HTMLInputElement> {
  otherRef?: Ref<HTMLInputElement>
  loading: boolean
  label: string
  fileTypes?: string[] // .pdf, .jpg and so on
}

export type UploadedFileType = {
  id?: string,
  filename: string,
  size: string,
  type: string,
}

const FileElement = ({ className, loading, label, fileTypes, otherRef, ...rest }: FileUploadButtonProps) => {

  const [focus, setFocus] = useState<boolean>(false)

  // Always call useRef
  const inputRef = useRef<HTMLInputElement>(null);
  const areaRef = useRef<HTMLLabelElement>(null);

  // Update the logic when using input and area
  const input = typeof window !== 'undefined' ? (otherRef || inputRef) : null;
  const area = typeof window !== 'undefined' ? areaRef : null;

  function handleKeyUp(event: KeyboardEvent<HTMLButtonElement>) {
    if (event.key === 'Enter' && area !== null) {
      area.current?.click()
    }
  }

  function acceptString(array: string[] | undefined) {
    if (!array) return;
    return array.map((curr) => curr.charAt(0) !== '.' ? `.${curr}` : curr).join(',');
  }

  return (
    <button
      className={`border border-primary-400 bg-gray-25 h-28 block w-full rounded-small hover:bg-gray-50 ${focus ? 'outline-none ring-4 ring-primary-50' : ''} ${className}`}
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      onKeyUp={handleKeyUp}
      type="button"
    >
      <label
        className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
        ref={area}
      >
        {
          loading
            ? (
              <Loader color="primary" className="scale-150" />
            ) : (
              <div className="font-bold text-primary-400">
                <CloudUploadIcon className="w-8 h-8 mx-auto mb-2" />
                <span>{label}</span>
              </div>
            )
        }

        <input type="file" ref={input} className="hidden" accept={acceptString(fileTypes)} {...rest} />
      </label>
    </button>
  )
}

export const FileUploadButton = forwardRef<HTMLInputElement, FileUploadButtonProps>(function FileUploadButton(props: FileUploadButtonProps, ref: Ref<HTMLInputElement>) {
  return (
    <FileElement otherRef={ref} {...props} />
  );
});

