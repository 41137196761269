import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange } from '~/sdk/shared';
import baseQuery from './base-query';
import removeDuplicates from '~/utils/removeDuplicates';

export type BoardMemberEntity = {
  ssn: number,
  email?: string,
  phone?: string,
  role: string,
  name: string,
};

export type BoardMemberEntityCollection = BoardMemberEntity[];

// The board members associated with a company
export const companyBoardMembersApi = createApi({
  reducerPath: 'companyBoardMembersApi',
  tagTypes: ['BoardMembers'],
  baseQuery,
  endpoints: (builder) => ({
    getBoardMembers: builder.query<BaseExchange<BoardMemberEntity[]>, void>({
      query: () => ({
        url: '/internal/v1/company/board-members?filter[exclude]=notSignatoryRoles',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<BoardMemberEntity[]> => {
        const originalData = response.data as BoardMemberEntity[];
        return { data: removeDuplicates(originalData, 'ssn') } as BaseExchange<BoardMemberEntity[]>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'BoardMembers', id: 'LIST' }] : [];
      },
    }),
  }),
});

export const {
  useGetBoardMembersQuery
} = companyBoardMembersApi;
