import { BaseResponse, BaseExchange } from '~/sdk/shared'
import API from  '~/sdk/client'

export type Entity = {
  url: string
}

export type FinancialSummaryMonthlyPayload = {
  productType: 'FACTORING'| 'QUICK_LOAN'| 'BUSINESS_LOAN'
  month: string // + year: "2023-02"
}

export async function post(payload: BaseExchange<FinancialSummaryMonthlyPayload>): Promise<BaseResponse<Entity>> {
  return await API.post('/internal/v1/company/reports/financial-summary-monthly', payload)
}
