import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resetAll } from '../actions';
import { Invoice, InvoiceSortColumn, get, InvoiceFilterQuery } from '~/sdk/internal/v2/company/invoices';
import { RootState } from '..';
import { PaginatedBaseResponse, BaseFilterRequest } from '~/sdk/shared';
import axios from 'axios';

import { createAsyncThunk } from '@reduxjs/toolkit';
import AppError from '~/utils/AppError';

type CacheId = string;

type InvoicePayload = {
  id: CacheId;
  page: number;
  hasMore: boolean;
  data: Invoice[];
}

type InvoiceEntry = {
  currentPage: number;
  hasMore: boolean;
  totalPages: number;
  invoices: Invoice[];
}

type InitialState = {
  status: 'init' | 'loading' | 'ready' | 'error';
  currentEntry: CacheId | null;
  cachedEntries: Record<CacheId, Partial<InvoiceEntry>>;
}

// Define an initial state
const initialState: InitialState = {
  status: 'init',
  currentEntry: null,
  cachedEntries: {},
};

// Selector to get the current invoices state
export const getCachedInvoices = createSelector(
  (state: RootState) => state.invoices,
  (invoicesState) => {

    if (!invoicesState.currentEntry) return null;
    return invoicesState.cachedEntries[invoicesState.currentEntry];
  }
);

export const fetchInvoices = createAsyncThunk<InvoiceEntry, BaseFilterRequest<InvoiceFilterQuery, InvoiceSortColumn>>(
  'invoices/fetchInvoices',
  async (requestParams, { rejectWithValue }) => {

    try {
      const response = await get(requestParams);
      const data = response.data as PaginatedBaseResponse<Invoice>;

      return {
        invoices: data.data,
        totalPages: data.meta.totalPages,
        currentPage: data.meta.currentPage,
        hasMore: data.meta.currentPage < data.meta.totalPages
      };

    } catch (error) {

      throw new AppError(error, { where: 'invoicesSlice', function: 'fetchInvoices', params: requestParams });
    }
  }
);

// Create the slice
const invoicesSlice = createSlice({
  name: 'invoices',
  initialState,
  reducers: {
    resetInvoicesState: () => {
      return initialState;
    },
    setEntry: (state, action: PayloadAction<InvoicePayload>) => {

      const current = state.cachedEntries[action.payload.id];
      const invoices = current?.invoices || [];

      // we sometimes receive invoices with duplicate ids from backend
      const invoiceMap = new Map(invoices.map(invoice => [invoice.id, invoice]));

      action.payload.data.forEach(newInvoice => {
          invoiceMap.set(newInvoice.id, newInvoice);
      });

      const combinedInvoices = Array.from(invoiceMap.values());

      const updated = {
        currentPage: action.payload.page,
        hasMore: action.payload.hasMore,
        invoices: combinedInvoices
      } as InvoiceEntry;

      const merged = { ...current, ...updated };

      // create or update cache entry for this cache ID
      state.cachedEntries[action.payload.id] = merged;
    },
    setCurrentEntry: (state, action: PayloadAction<CacheId>) => {
      state.currentEntry = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState)
      .addCase(fetchInvoices.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.status = 'ready';
      })
  },
});

export const { resetInvoicesState, setCurrentEntry, setEntry } = invoicesSlice.actions;

// Export the action creators and the reducer
export default invoicesSlice.reducer;
