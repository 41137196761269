import API from '~/sdk/client';
import { BaseResponse } from '~/sdk/shared';

export interface Entity {
  bankConnectionDiscountBusinessLoan: number | null;
  bankConnectionDiscountFactoring: number | null;
  bankConnectionDiscountInvoiceDiscounting: number | null;
  erpConnectionDiscountBusinessLoan: number | null;
  erpConnectionDiscountFactoring: number | null;
  erpConnectionDiscountInvoiceDiscounting: number | null;
}

export async function get(): Promise<BaseResponse<Entity>> {
  return API.get('/internal/v1/settings/discount')
}
