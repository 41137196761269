// hooks/useAppDispatch.ts

/**
 * This is the typed version of useDispatch, you need to define
 * the type of the dispatch function in your application.
 * This involves creating a typed version of the
 * Redux store and using it as the type for the dispatch function.
 */
import { useDispatch } from 'react-redux';
import { AppDispatch } from '~/store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
