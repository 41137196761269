import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseFilterRequest, PaginatedBaseResponse, BaseExchange, BaseResponse, buildRequestQuery } from '~/sdk/shared';
import baseQuery from './base-query';
import { GetCustomersFilterQuery, CreateCustomerRequest, CustomerEntity, UpdateCustomer } from '~/sdk/internal/v2/company/customers';

export const customersApi = createApi({
  reducerPath: 'customersApi',
  baseQuery,
  tagTypes: ['Customers'],
  endpoints: (builder) => ({
    getCustomers: builder.query<PaginatedBaseResponse<CustomerEntity>, BaseFilterRequest<GetCustomersFilterQuery>>({
      query: (request) => {
        return ({
          url: `/internal/v2/company/customers${buildRequestQuery(request)}`,
          method: 'GET',
        })
      },
      providesTags: (result) => {
        return result ? [{ type: 'Customers', id: 'LIST' }] : [];
      },
    }),
    getAllCustomers: builder.query<PaginatedBaseResponse<CustomerEntity>, BaseFilterRequest<GetCustomersFilterQuery>>({
      query: () => {
        return ({
          url: '/internal/v2/company/customers?size=200',
          method: 'GET',
        })
      },
      providesTags: (result) => {
        return result ? [{ type: 'Customers', id: 'LIST' }] : [];
      },
    }),
    createCustomer: builder.mutation<BaseExchange<CustomerEntity>, BaseExchange<CreateCustomerRequest>>({
      query: (payload) => ({
        url: '/internal/v2/company/customers',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response): BaseExchange<CustomerEntity> => {
        return { data: response.data } as BaseExchange<CustomerEntity>;
      },
      invalidatesTags: [{ type: 'Customers' }]
    }),
    getCustomerById: builder.query<BaseExchange<CustomerEntity>, string>({
      query: (id) => ({
        url: `/internal/v2/company/customers/${id}`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Customers', id: result.data.id }] : [];
      },
    }),
    updateCustomer: builder.mutation<BaseResponse<CustomerEntity>, { id: string; payload: BaseExchange<UpdateCustomer> }>({
      query: ({ id, payload }) => ({
        url: `/internal/v2/company/customers/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Customers' }]
    }),
  }),
});

export const {
  useGetCustomersQuery,
  useCreateCustomerMutation,
  useGetCustomerByIdQuery,
  useUpdateCustomerMutation,
} = customersApi;
