import { BaseResponse } from '~/sdk/shared';
import { ERPName } from '~/config/erp';
import API from '~/sdk/client';

export type CompanyType = 'AB' | 'EF' | 'HB/KB' | 'OTHER' |'INDIVIDUAL';

export type AuthenticatedCompanyEntity = {
  connectionDiscount: boolean;
  createdAt: string;
  creditScore: number;
  hasApprovedFactoringWithinThreeMonths: boolean;
  hasFactoringActiveProduct: boolean;
  id: number;
  identityNumber: string;
  isSyncing: boolean;
  loanAmount: number;
  name: string;
  needRepurchase: boolean;
  otherCapType: null | string ;
  partner: string;
  phone: string;
  providers: Provider[];
  syncedAt: string;
  updatedAt: string;
  showOnboarding: boolean;
  type: CompanyType
};

export interface Provider {
  createdAt: string;
  displayName: ERPName;
  enabled: boolean;
  firstSyncOngoing: boolean;
  id: number;
  lastsync: string | null;
  name: string;
}

export async function getAuthenticatedCompany(): Promise<BaseResponse<AuthenticatedCompanyEntity>> {
  return await API.get('internal/v1/company');
}
