import { createApi } from '@reduxjs/toolkit/query/react';
import { BaseExchange, BaseResponse } from '~/sdk/shared';
import baseQuery from './base-query';
import { ProductType } from '~/sdk/internal/v2/company/applications';

// the original type from the backend
type GuarantorInitType = {
  id: number,
  identityNumber: number,
  phone: string,
  name: string,
  email: string,
  active: boolean,
  approvedFor: ProductType[]
}

// the type we want to use in the app.
export type GuarantorEntity = {
  id: number,
  ssn: number,
  phone: string,
  name: string,
  email: string,
  active: boolean,
  approvedFor: ProductType[]
}

export type GuarantorCreatePayload = {
  ssn: number,
  email: string,
  phone?: string
}

export type GuarantorType = {
  guarantor: 'SELF' | 'OTHER'
}

// The guarantors associated with a company
export const guarantorsApi = createApi({
  reducerPath: 'guarantorsApi',
  tagTypes: ['Guarantors'],
  baseQuery,
  endpoints: (builder) => ({
    getGuarantors: builder.query<BaseExchange<GuarantorEntity[]>, void>({
      query: () => ({
        url: '/internal/v1/company/guarantors',
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<GuarantorEntity[]> => {
        const guarantorsArray = response?.data as GuarantorInitType[];

        // ensure consistent property names
        const transformedData = guarantorsArray.map((guarantor) => ({
          ...guarantor,
          ssn: guarantor.identityNumber,
        }));

        return { data: transformedData } as BaseExchange<GuarantorEntity[]>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'Guarantors', id: 'LIST' }] : [];
      },
    }),
    getGuarantor: builder.query<BaseExchange<GuarantorEntity>, number>({
      query: (id) => ({
        url: `/internal/v1/company/guarantors/${id}`,
        method: 'GET'
      }),
      transformResponse: (response): BaseExchange<GuarantorEntity> => {
        const originalData = response?.data as GuarantorInitType;
        const transformedData = {
          ...originalData,
          ssn: originalData.identityNumber,
        };

        return { data: transformedData } as BaseExchange<GuarantorEntity>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'Guarantors', id: result.data.id }] : [];
      },
    }),
    createGuarantor: builder.mutation<BaseExchange<GuarantorEntity>, BaseExchange<GuarantorCreatePayload>>({
      query: (payload) => ({
        url: '/internal/v1/company/guarantors',
        method: 'POST',
        body: payload,
      }),
      transformResponse: (response): BaseExchange<GuarantorEntity> => {
        const originalData = response?.data as GuarantorInitType;
        const transformedData = {
          ...originalData,
          ssn: originalData.identityNumber,
        };

        return { data: transformedData } as BaseExchange<GuarantorEntity>;
      },
      invalidatesTags: [{ type: 'Guarantors' }]
    }),
    patchGuarantor: builder.mutation<BaseResponse<GuarantorEntity>, { id: number; payload: BaseExchange<Partial<GuarantorCreatePayload>> }>({
      query: ({ id, payload }) => ({
        url: `/internal/v1/company/guarantors/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Guarantors' }]
    }),
    deleteGuarantor: builder.mutation<void, number>({
      query: (id) => ({
        url: `/internal/v1/company/guarantors/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [{ type: 'Guarantors' }]
    }),
  }),
});

export const {
  useGetGuarantorsQuery,
  useGetGuarantorQuery,
  useCreateGuarantorMutation,
  usePatchGuarantorMutation,
  useDeleteGuarantorMutation,
} = guarantorsApi;
