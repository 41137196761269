import { BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export type ProvidersEntity2 = {
  id: number
  name: string
  label: string
  images: {
    logo: {
      large: string
      thumb: string
    }
  }
  automaticBookkeeping: null | boolean 
}

export async function get(): Promise<BaseResponse<ProvidersEntity2>> {
  return await API.get('internal/v2/providers')
}
