import { Entity as MeEntity } from 'sdk/internal/v1/user/me';
import { BaseExchange } from '~/sdk/shared';
import { useAppDispatch } from './useAppDispatch';
import * as API from '~/sdk'
import { useSelector } from 'react-redux';
import { RootState } from '~/store';
import { fetchUser, patchUser, resetUserState } from '~/store/user/userSlice';

type VerificationField = API.Internal.V1.User.Me.VerificationField
type VerificationOrigin = API.Internal.V1.User.Me.VerificationOrigin

// lol
export function useMe() {

  const userState = useSelector((state: RootState) => state.user);
  const dispatch = useAppDispatch();

  function isVerified(field: VerificationField, origin?: VerificationOrigin) {
    const user = userState.data;
    return user && user.verifications.some(val => {
      return val.field === field && (origin ? val.origin === origin : true);
    });
  }

  function hasPermission(permission: API.Internal.V1.User.Me.Permission) {
    return userState.data && userState.data.permissions.includes(permission) || false;
  }

  async function refetch() {
    await dispatch(fetchUser());
  }

  function reset() {
    dispatch(resetUserState());
  }

  async function patch(payload: BaseExchange<Partial<MeEntity>>) {

    const data = await dispatch(patchUser(payload)).unwrap();

    return data;
  }

  return {
    data: userState.data,
    state: userState,
    companies: userState.companies,
    currentCompany: userState.currentCompany,
    isLoading: userState.loading,
    refetch,
    reset,
    patch,
    isVerified,
    hasPermission,
  };
}
