import * as SDK from '~/sdk'
import { AxiosResponse } from 'axios'
import { BaseExchange } from '~/sdk/shared'
import { poll } from '@monto/promise-utils'
import AppError from './AppError'

export enum BankIdState {
  Initial,
  Submitted,
  WaitingForLegitimation,
  Legitimized,
  Failed,
  Cancelled
}

export enum BankIdScreen {
  Initial,
  Pending,
}

export function getTarget(): SDK.Internal.V1.Auth.BankId.TargetUnit {
  return window.innerWidth < 768
    ? SDK.Internal.V1.Auth.BankId.TargetUnit.This
    : SDK.Internal.V1.Auth.BankId.TargetUnit.Other
}

export function openNewWindow(url: string): void {
  window.open(url, '_blank')?.focus()
}

export async function initiateBankId(ssn: string, target: SDK.Internal.V1.Auth.BankId.TargetUnit): Promise<SDK.Internal.V1.Auth.BankId.Response> {

  try {
    const payload = { data: { target, ssn } };
    const { data: response } = await SDK.Internal.V1.Auth.BankId.post(payload);

    if (target === SDK.Internal.V1.Auth.BankId.TargetUnit.This) {
      openNewWindow(response.data.autoStartUrl);
    }

    return response.data;
  } catch (error) {
    throw new AppError(error, { function: 'initiateBankId', ssn, target });
  }
}

export async function getBankIdSession(sessionId: string, target: SDK.Internal.V1.Auth.BankId.TargetUnit): Promise<SDK.Internal.V1.Auth.BankId.Session.AccessToken> {
  try {
    const payload = {
      data: {
        sessionId,
        target
      }
    };

    const { data: response } = await poll<AxiosResponse<BaseExchange<SDK.Internal.V1.Auth.BankId.Session.AccessToken>>>(
      () => SDK.Internal.V1.Auth.BankId.Session.post(payload),
      ({ data: response }) => {
        return !!response?.data?.accessToken;
      },
      90_000,
      3_000,
    ) as any;

    return response.data;
  } catch (error) {
    throw new AppError(error, { function: 'getBankIdSession', sessionId, target });
  }
}

export async function getAutoLoginUrl(location = '/admin'): Promise<string> {
  try {
    const payload = { data: { location } };
    const { data: response } = await SDK.Internal.V1.User.AutoLogin.post(payload);

    return response.data.url;
  } catch (error) {
    throw new AppError(error, { function: 'getAutoLoginUrl', location });
  }
}

