import { createApi } from '@reduxjs/toolkit/query/react';
import { PaginatedBaseResponse, buildRequestQuery, BaseFilterRequest, BaseResponse, BaseExchange } from '~/sdk/shared';
import { Invoice, InvoiceSortColumn, CreateInvoiceRequest, InvoiceFilterQuery, UpdateInvoice } from '~/sdk/internal/v2/company/invoices';
import baseQuery from './base-query';

export const invoicesApi = createApi({
  reducerPath: 'invoicesApi',
  tagTypes: ['Invoices'],
  baseQuery,
  endpoints: (builder) => ({
    getInvoices: builder.query<PaginatedBaseResponse<Invoice>, BaseFilterRequest<InvoiceFilterQuery, InvoiceSortColumn>>({
      query: (request) => ({
        url: `/internal/v2/company/invoices${buildRequestQuery(request)}`,
        method: 'GET',
      }),
      providesTags: (result) => {
        return result ? [{ type: 'Invoices', id: 'LIST' }] : [];
      },
    }),
    getInvoice: builder.query<BaseExchange<Invoice>, string>({
      query: (id) => ({
        url: `/internal/v2/company/invoices/${id}`,
        method: 'GET',
      }),
      transformResponse: (response): BaseExchange<Invoice> => {
        return { data: response.data } as BaseExchange<Invoice>;
      },
      providesTags: (result) => {
        return result ? [{ type: 'Invoices', id: result.data.id }] : [];
      },
    }),
    createInvoice: builder.mutation<BaseResponse<Invoice>, BaseExchange<CreateInvoiceRequest>>({
      query: (payload) => ({
        url: '/internal/v2/company/invoices',
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [{ type: 'Invoices' }]
    }),
    patchInvoice: builder.mutation<BaseResponse<Invoice>, { id: string; payload: BaseExchange<Omit<CreateInvoiceRequest, 'foreignIdentityNumber'>> }>({
      query: ({ id, payload }) => ({
        url: `/internal/v2/company/invoices/${id}`,
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Invoices', id }]
    }),
    updateInvoice: builder.mutation<BaseResponse<Invoice>, { id: string; payload: BaseExchange<UpdateInvoice> }>({
      query: ({ id, payload }) => ({
        url: `/internal/v2/company/invoices/${id}`,
        method: 'PUT',
        body: payload,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Invoices', id }]
    }),
  }),
});

export const {
  useGetInvoicesQuery,
  useGetInvoiceQuery,
  useCreateInvoiceMutation,
  usePatchInvoiceMutation,
  useUpdateInvoiceMutation,
} = invoicesApi;
