import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  createAction,
  createSelector,
} from '@reduxjs/toolkit';

import { get as getProvider } from '~/sdk/internal/v1/company/providers';
import { BaseResponse } from '~/sdk/shared';
import { poll } from '~/utils/poll';
import { sync as syncJobs } from '~/sdk/internal/v1/company/jobs';
import { resetAll } from '../actions';

import { connect, ProviderName, ConnectResponse, InputField } from '~/sdk/internal/v1/providers';

// provider connect type
import { Provider as ConnectingProviderEntity } from '~/sdk/internal/v1/providers';
import { get as getConnectedProvider } from '~/sdk/internal/v1/providers';

// provider list type
import { Entity as ProviderEntity } from '~/sdk/internal/v1/company/providers';
import { RootState } from '~/store';
import AppError from '~/utils/AppError';

export enum ConnectionStatus {
  Initial = 'INITIAL',
  AlreadyConnected = 'ALREADYCONNECTED',
  Connecting = 'CONNECTING',
  Connected = 'CONNECTED',
}

export enum ProviderSyncStatus {
  Initial,
  Ready,
  Syncing
}

export type ProvidersState = {
  entities: Record<string, ProviderEntity | undefined>;
  ids: (string | number)[];
  connectingProvider: ConnectingProviderEntity | null; // The provider while connecting
  connectedProvider: ProviderEntity | null; // The connected provider
  connectResponse: ConnectResponse | null;
  connectionStatus: ConnectionStatus;
  syncStatus: ProviderSyncStatus;
  isConnected: boolean;
  loading: boolean;
  error: string | undefined;
  isPolling: boolean; // not sure if we need this, as we already have sync status
  status: 'ready' | 'init';
  firstConnectionSuccess: boolean;
};

const providersAdapter = createEntityAdapter<ProviderEntity>({
  selectId: (provider) => provider.id,
});

const initialState: ProvidersState = {
  ...providersAdapter.getInitialState(),
  connectingProvider: null,
  connectedProvider: null,
  connectionStatus: ConnectionStatus.Initial,
  syncStatus: ProviderSyncStatus.Initial,
  connectResponse: null,
  isConnected: false,
  loading: false,
  error: undefined,
  isPolling: false,
  status: 'init',
  firstConnectionSuccess: false,
};

// get the available providers that can be connected to
export const fetchProviders = createAsyncThunk(
  'providers/fetchProviders',
  async () => {
    try {
      const response: BaseResponse<ProviderEntity[]> = await getProvider();
      return response.data.data;
    } catch(error) {
      throw new AppError(error, {
        where: 'providersSlice',
        function: 'fetchProviders',
      });
    }
  }
);

// connect a provider to this company
export const connectProvider = createAsyncThunk(
  'providers/connectProvider',
  async (
    { displayName, fields }: { displayName: string; fields?: InputField[] },
  ) => {

    // ensure array
    fields = fields || [];
    try {
      const response: BaseResponse<ConnectResponse> = await connect(
        displayName as ProviderName,
        { data: { fields } }
      );
      return response.data.data;
    } catch (error) {
      throw new AppError(error, {
        where: 'providersSlice',
        function: 'connectProvider',
        fields
      });
    }
  }
);

// the provider that is currently connected to this company
export const fetchConnectedProvider = createAsyncThunk(
  'providers/fetchConnectedProvider',
  async (displayName: string) => {
    try {
      const response: BaseResponse<ConnectingProviderEntity> = await getConnectedProvider(
        displayName as ProviderName
      );
      return response.data.data;
    } catch(error) {
      throw new AppError(error, {
        where: 'providersSlice',
        function: 'fetchConnectedProvider',
        displayName
      });
    }
  }
);

// select the latest provider.
export const selectLatestProvider = createSelector(
  (state: RootState) => state.providers.ids,
  (state: RootState) => state.providers.entities,
  (ids, entities) => {
    if (!ids.length) return null;

    return ids
      .map((id) => entities[id])
      .filter((p) => p !== undefined)
      .sort(
        (a, b) => {
          // It seems like typescript does not properly understand sort arrays.
          // As we filtered out the undefined already, using the non-nullish assertion
          // seems to be a save thing to do at this point.
          return new Date(b!.createdAt).getTime() - new Date(a!.createdAt).getTime()
        }
      )[0];
  }
);

// will provide us with the correct sync status
function computeStatus(provider: ProviderEntity | null): ProviderSyncStatus {
  if (provider?.isSyncing === true) return ProviderSyncStatus.Syncing;
  return ProviderSyncStatus.Ready;
}

// action to update the sync status
export const updateSyncStatus = createAction<ProviderSyncStatus>('providers/updateSyncStatus');

// action to update connection status
export const updateConnectionStatus = createAction<ConnectionStatus>('providers/updateConnectionStatus');

// action to update polling status
export const updatePollingStatus = createAction<boolean>('providers/updatePollingStatus');

// action to set the latest active provider
export const setLatestActiveProvider = createAction<ProviderEntity>('providers/setLatestActiveProvider');

// action to set the latest active provider
export const setConnectedProvider = createAction<ProviderEntity | null>('providers/setLatestActiveProvider');

// action to set the connectResponse
export const updateConnectResponse = createAction<ConnectResponse | null>('providers/updateConnectResponse');

// action to set the connectResponse
export const setFirstConnectionSuccess = createAction<boolean>('providers/setFirstConnectionSuccess');

// poll for provider
export const performPoll = createAsyncThunk<
  void,
  {
    condition?: (provider: ProviderEntity | null) => boolean;
    interval?: number;
  },
  {
    state: RootState;
    extra: void;
  }
>(
  'providers/performPoll',
  async ({ condition, interval }, { getState, dispatch }) => {

    dispatch(updatePollingStatus(true));

    async function getLatestActiveProvider() {

      await dispatch(fetchProviders());

      const state: RootState = getState();
      const selectedProvider = selectLatestProvider(state);

      return Promise.resolve(selectedProvider !== undefined ? selectedProvider : null);
    };

    const provider = await poll<ProviderEntity | null>(
      getLatestActiveProvider,
      condition || ((provider) => !provider || !provider.isSyncing),
      300_000,
      interval || 5_000,
    );

    // Dispatch actions to update the sync and polling status
    // dispatch(resetInvoicesState());
    dispatch(updateSyncStatus(computeStatus(provider)));
    dispatch(updatePollingStatus(false));
    dispatch(updateConnectResponse(null))
  }
);

/**
 * Allows user to manually sync a provider connection
 */
export const syncProvider = createAsyncThunk<
  void,
  void,
  {
    state: RootState;
    extra: void;
  }
>('providers/sync', async (_, { dispatch }) => {
  // Dispatch an action to update the status to syncing
  dispatch(updateSyncStatus(ProviderSyncStatus.Syncing));

  await syncJobs({ type: 'QUICK', assess: false });
  await dispatch(performPoll({}));
});

const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    resetProvidersState: () => initialState,
    setFirstConnectionSuccess: (state, action) => {
      state.firstConnectionSuccess = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(resetAll, () => initialState )
      .addCase(fetchProviders.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchProviders.fulfilled, (state, action) => {
        providersAdapter.setAll(state, action.payload);
        state.loading = false;
        state.status = 'ready';

        // Get the latest active provider from the action.payload
        const latestActiveProvider = action.payload
          .filter((p) => p !== undefined)
          .sort(
            (a, b) => {
              return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
            }
          )[0] || null;

        // update state
        state.connectedProvider = latestActiveProvider;
        state.syncStatus = computeStatus(state.connectedProvider);
      })
      .addCase(fetchProviders.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(connectProvider.pending, (state) => {
        state.loading = true;
        state.connectionStatus = ConnectionStatus.Connecting;
        state.error = undefined;
      })
      .addCase(connectProvider.fulfilled, (state, action) => {
        state.connectResponse = action.payload;
        if (action.payload.status === 'DONE') {
          state.connectionStatus = ConnectionStatus.Connected;
          state.isConnected = true;
          state.connectResponse = action.payload
        }
        state.loading = false;
        state.status = 'ready';
      })
      .addCase(connectProvider.rejected, (state, action) => {
        state.loading = false;
        state.connectionStatus = ConnectionStatus.Initial;
        state.isConnected = false;
        state.error = action.error.message;
      })
      .addCase(updateSyncStatus, (state, action) => {
        state.syncStatus = action.payload;
      })
      .addCase(updateConnectionStatus, (state, action) => {
        state.connectionStatus = action.payload;
      })
      .addCase(updateConnectResponse, (state, action) => {
        state.connectResponse = action.payload;
      })
      .addCase(setConnectedProvider, (state, action) => {
        state.connectedProvider = action.payload;
      })
      .addCase(updatePollingStatus, (state, action) => {
        state.isPolling = action.payload;
      })
      .addCase(fetchConnectedProvider.pending, (state) => {
        state.loading = true;
        state.error = undefined;
      })
      .addCase(fetchConnectedProvider.fulfilled, (state, action) => {
        state.connectingProvider = action.payload;
        state.loading = false;
      })
      .addCase(fetchConnectedProvider.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { resetProvidersState } = providersSlice.actions;

export default providersSlice.reducer;
