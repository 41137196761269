
import { BaseExchange, BaseResponse } from '~/sdk/shared'
import API from '~/sdk/client'

export type Entity = {
  url: string,
}

export type DownloadCollectionType = 'yearly' | 'monthly' | 'accounting' | 'sold' | 'transaction' | 'business_loan';

export interface AccountingsDownloadPayload {
  dateFrom?: string
  dateTo?: string
  fileType: 'CSV' | 'PDF'
  productType: 'BUSINESS_LOAN' | 'FACTORING' | 'QUICK_LOAN' | 'TRANSACTIONS',
}

export function createDownloadQueryParams(file: DownloadCollectionType, data: AccountingsDownloadPayload) {

  const { productType } = data

  // TODO: remove this when backend is fixed
  if (file === 'transaction') {
    data.productType = 'TRANSACTIONS'
  } else if (file === 'business_loan') {
    data.productType = 'BUSINESS_LOAN'
  } else if (file === 'accounting') {
    data.productType = 'QUICK_LOAN'
  } else if (file === 'sold') {
    data.productType = 'FACTORING'
  } else {
    data.productType = productType
  }

  return data;
}

export async function get(payload: BaseExchange<AccountingsDownloadPayload>): Promise<BaseResponse<Entity>> {
  return await API.get('internal/v1/company/accountings/download', { params: payload.data});
}
