import * as Sentry from '@sentry/nextjs';

/**
 * Custom error class for application-specific error handling.
 * Extends the native JavaScript Error class and integrates with Sentry for error logging.
 *
 * This class can be instantiated with either an Error object or a custom error message string.
 * It captures the error and provides the capability to add additional context for Sentry logging.
 *
 * Example Usage:
 *
 * try {
 *   // code that might throw an error
 * } catch (error) {
 *   throw new AppError(error, {
 *     where: 'functionName',
 *     action: 'description of the action during which the error occurred',
 *     // ...any other relevant context
 *   });
 * }
 *
 * or
 *
 * throw new AppError('Custom error message', { customContext: 'value' });
 *
 */
class AppError extends Error {

  private originalError: Error | null;
  private context: Record<string, any> | undefined;

  /**
   * Constructs an AppError instance.
   *
   * @param {Error | string | unknown} error - The original error object or a custom error message string.
   * @param {Record<string, any>} [context] - Optional additional context to attach with the error for Sentry logging.
   */
  constructor(error: Error | string | unknown, context?: Record<string, any>) {
    if (error instanceof Error) {
      super(error.message);
      this.originalError = error;
    } else if (typeof error === 'string') {
      super(error);
      this.originalError = new Error(error);
    } else {
      super('An unknown error occurred');
      this.originalError = null;
    }

    this.name = 'AppError';
    this.context = context;

    if (!(error instanceof Error) && !(typeof error === 'string')) {
      this.context = { ...this.context, unknownError: error };
    }

    this.logErrorWithSentry();
  }

  /**
   * Logs the error to Sentry with the provided context.
   * If the original error is not an instance of Error, it logs the AppError instance itself.
   */
  private logErrorWithSentry() {
    Sentry.withScope(scope => {
      if (this.context) {
        Object.entries(this.context).forEach(([key, value]) => {
          scope.setExtra(key, value);
        });
      }

      const errorToCapture = this.originalError ?? this;
      Sentry.captureException(errorToCapture);
    });
  }
}

export default AppError;
