import { ReactNode, useEffect } from 'react';
import useLocalStorage from '~/hooks/useLocalStorage';
import { useAppDispatch } from '~/hooks/useAppDispatch';
import { setAppContext, setCurrentCompanyId, setIsConnected, setRefContext } from '~/store/application/applicationSlice';
import useAppState from '~/hooks/useApp';
import useApplicationReset from '~/hooks/useApplicationReset';
import { useMe } from '~/hooks/useMe';
import { setFirstConnectionSuccess, syncProvider } from '~/store/providers/providersSlice';
import { useRouter } from 'next/router';
import { getAuthenticatedCompany } from '~/sdk/internal/v1/company/authenticated-company';

/**
 * Set global state, or set cookies / localStorage in this wrapper
 */
const GlobalStateInitializer: React.FC<{ children: ReactNode }> = ({ children }) => {

  const router = useRouter();
  const dispatch = useAppDispatch();
  const appState = useAppState();
  const user = useMe();

  const appContext = useLocalStorage('appContext');
  const refContext = useLocalStorage('refContext');
  const refProduct = useLocalStorage('refProduct');
  const companyId = useLocalStorage('companyId');
  const useOnboarding = useLocalStorage('useOnboarding');

  const resetApplication = useApplicationReset();

  const checkAuthenticatedCompany = async () => {
    const { data } = await getAuthenticatedCompany();

    useOnboarding.set(data.data.showOnboarding);
    appState.setOnboarding({ useOnboarding: data.data.showOnboarding });

    const connectedProvider = data.data.providers.find((provider) => provider.enabled);
    dispatch(setIsConnected(!!connectedProvider));

    appState.updateCurrentCompany({
      companyType: data.data.type,
    });

    // start syncing when company has connected erp
    if (connectedProvider) {
      dispatch(syncProvider());

      // is this the first successful connection?
      if (connectedProvider.firstSyncOngoing === true && connectedProvider.lastsync === null) {
        dispatch(setFirstConnectionSuccess(true));
      }
    }
  }

  useEffect(() => {

    // no company...
    if (!user.state.currentCompany?.id) return;

    appState.setStatus('loading');

    // set the global company id
    appState.setCurrentCompanyId(user.state.currentCompany?.id);

    // check if we need to do a hard refresh
    if (user.state.currentCompany?.id !== companyId.get()) {

      resetApplication.resetAll('soft');

      // update global company id.
      dispatch(setCurrentCompanyId(user.state.currentCompany.id));
    }

    // check if user needs things such as onboarding
    checkAuthenticatedCompany();

    // add the companyId to local storage
    companyId.set(user.state.currentCompany?.id);

    appState.setStatus('ready');

  }, [user.state.currentCompany]);

  // if user switches company, make sure that this change is
  // also reflected to open applications in other browser tabs.
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'companyId') {
        resetApplication.resetUser();
        user.refetch();
        resetApplication.resetAll('soft');
      }
    };

    if (typeof window !== undefined) {

      // listen for changes in local storage.
      window.addEventListener('storage', handleStorageChange);

      // Cleanup listener on unmount
      return () => {
        window.removeEventListener('storage', handleStorageChange);
      };
    }
  }, []);


  useEffect(() => {

    if (!router.query) return;

    // set product
    if (router.query.product) refProduct.set(router.query.product);

    // set app context and ref context based on query params
    if (router.query.broker == 'lendo') {
      refContext.set('lendo');
      dispatch(setRefContext('lendo'));
    }

    if (router.query.bidder == 'fortnox') {
      appContext.set('fortnox');
      dispatch(setAppContext('fortnox'));
    }

    if (router.query.bidder == 'capcito') {
      appContext.set('capcito');
      dispatch(setAppContext('capcito'));
    }

  }, [router.query, dispatch, appState.data.currentCompanyId]);

  return <>{children}</>;
};

export default GlobalStateInitializer;
